/* Index.css */
.animate__animated {
    animation-duration: 1s;
  }
  
  .animate__fadeInRight {
    animation-name: fadeInRight;
  }
  
  .animate__fadeInLeft {
    animation-name: fadeInLeft;
  }
  /* index.css */
@import 'tailwindcss/tailwind.css';

  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  