.yoga-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
}

.yoga-header {
  text-align: center;
  margin-bottom: 30px;
}

.yoga-header h1 {
  font-size: 3.5rem;
  color: #333;
}

.yoga-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.yoga-info h2,
.yoga-poses h2,
.yoga-benefits h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.yoga-info p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

.yoga-poses ul,
.yoga-benefits ul {
  list-style-type: none;
  padding: 0;
}

.yoga-pose {
  margin-bottom: 10px;
}

.yoga-pose a {
  text-decoration: none;
  color: #007bff;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.yoga-pose a:hover {
  color: #0056b3;
}

.yoga-register {
  text-align: center;
  margin-top: 30px;
}

.register-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-btn:hover {
  background-color: #0056b3;
}

.yoga-footer {
  text-align: center;
  margin-top: 50px;
  color: #666;
  font-size: 1rem;
}
