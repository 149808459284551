


.log{
  width:200px;
  height: 100px;
  margin: -8% 0%;
}
 
.cnavbar ul li,.cnavbar ul ul li{
    padding: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
}
.logo-video {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
}


.animated-gradient ,.c-1 span ,.c-4,.div30{
    animation: animateBg 5s linear infinite;
    background-image: linear-gradient(90deg,#74aab7,#2c43b5,#616745,#8b4f0f,#5b67a7);
    background-size: 400% 100%;
  }
  @keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }
  .cnavbar{
    position: sticky;
    top: 0;
    z-index: 4000;
    font-family: 'Roboto', sans-serif;
    height: 90px;
  }
  .c-0{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 60px;
    background-color: white;
  }
  .space{
    margin-left: 4%;
  }
  .c-1{
    margin-left: 120px;
    margin-top: 60px;
    
    width: 100%;
  }
  .c-2{
    width: 550px;
    height:500px;
    margin-top: 10%;
  }
  .c-1 h4{
    font-weight: 400;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 25px;
  }
  .c-1 h5{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .c-1 span{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            -webkit-text-fill-color: transparent; 
            -webkit-background-clip: text; 
  }
  .btn1,.btn2{
    font-size: 15px;
    margin-top: 20px;
    margin-right: 15px;
    border-radius: 159px;
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .btn1{
    background-color: rgb(55, 146, 185);
    border: none;
    color: white;
  }
  .btn2{
    border-color: #74aab7;
    font-size: 18px;
  }
  .btn1:hover{
    background-color: #b3c947;
  }
  .btn2:hover{
  
    border:2px solid black;
    color: rgb(55, 146, 185);
  }
  .c-4{
    margin-top: 40px;
    margin-right: 35%;
  
    padding: 20px;
    padding-right: 20px;
    font-size: 18px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-top-right-radius: 40px; /* Adjust the value to your desired radius */
    border-bottom-right-radius: 40px; /* Adjust the value to your desired radius */
    background-color: dark;
  }
  .gradien{
             font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            -webkit-text-fill-color: transparent; 
            -webkit-background-clip: text; 
            font-style:oblique;
  }
  .text-center{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style:oblique;
  }
  .company-list{
    padding: 60px 0px;
    background-color: white;
  }

  
  
.cnavbar ul ul li:hover{
 background-color: #5a642a;
 transition: all 1s;
}
.c-6{
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: white;
}
.c-6>div:nth-child(1){
  border: none;
  box-shadow: 4px 4px 4px 4px rgba(81, 76, 76, 0.154);
  padding-top: 20px;
  padding-right: 20px;
}
.c-6 li{
  margin-top: 30px;
}
.c-6 a{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 200;
}

.c-6 a:hover{
  color: #74aab7;
}
.scroll {
  width: 800px;
  height: 650px;
  overflow: auto;
  background-color: white;
  color: black;
}
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #6995b2; /* Set the color of the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the scrollbar track */
}

/* Optional: Set the color when the user hovers over the scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9;
}
.c-8{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 22px;
}
#c-9 {
  background-color: #fff1f1;
  border-radius: 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 18px;
  padding: 10px;
  padding-left: 14px;
}
#c-10{
  background-color: #fefef0;
}
.c-11{
  display: flex;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-top: 10px;
  padding-right: 10px;
  margin: 20px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 4px 4px 4px 4px rgba(81, 76, 76, 0.154);
}
#c-10{
  margin: 20px 0px;
  padding: 10px;
  border-radius:10px;
}
#c-10 h3 , #c-10>p{
  margin-left:30px ;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.c-11 h5{
  margin: 20px;
  margin-top: 30px;
}
.c-11 h5{
  font-size: 20px;
}
#c-14{
  background-color:#f0f7fe ;
}
.c-12{
  display: flex;
}
.c-12 div:nth-child(1){
  width: 100px;
}
.c-12 div:nth-child(1)>img{
  height: 600px;
}
.c-12 div:nth-child(2){
  margin-top: 40px;
  margin-left: 20px;
}
.c-13 img{
  width: 600px;
  height: 150px;
}
.c-13 h4 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: 40px;
  text-decoration: underline;
}
.c-13 h5{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-bottom: 40px;
}
#c-14>h5{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-left: 20px;
  padding-top: 30px;
  font-size: 22px;
}
#c-15{
  background: #f4fef0;
  margin: 35px 0px;
  border-radius: 20px;
  padding: 20px;
}
#c-15 img{
  width: 70px;
  height: 70px;
  margin: 30px;
  margin-bottom: 0px;
}
#c-15>h4{
  margin-left: 20px;
  padding-top: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.c-16{
  display: flex;
  
}
.c-16>p{
  font-weight: 300;
  margin-top: 20px;
  font-size: 18px;
  
}
#c-17>h4,#c-17>p{
  padding-left: 20px;
  font-family:Arial, Helvetica, sans-serif;
}
.c-18{
  display: flex;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.c-18 img{
  width: 20%;
  margin-left: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.c-18 div{
  padding: 50px 29px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 4px rgba(81, 76, 76, 0.154);
}
.div30{
  padding-bottom: 70px;
}
.div31{
  text-align: center;
  padding: 70px 0px;
  color: white;
}
.div31 h1{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-bottom: 10px;
}
.div31 p{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.div32{
  display: flex;
  justify-content: space-around;
  color: white;
  margin-left: 40px;
}

.div32 h3{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 32px;
}
.div32 h4,.div32 h5,.div32 h6{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 4px 0px;
}
.div32 img{
  width: 5%;
}
.div32 h6{
  font-size: 15px;
}
.div32 h5{
  margin-right: 70px;
  font-size: 15px;
}
.div32 button{
  color: white;
  margin-left: 120px;
  margin-top: 30px;
  font-size: 17px;
  padding:10px 15px;
  border-radius: 8px;
  background-color:#073333;
}
.horizontal-scroll-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  
  padding: 20px; /* Optional: Add padding for spacing */
}

.horizontal-scroll-container>div {
  min-width: 400px; /* Set a minimum width for each item */
  border: 1px solid #ddd; 
  margin: 10px; /* Optional: Add margin between items */
  margin-right: 120px;
  margin-top: 30px;
  margin-bottom: 70px;
  
}
.h-21 div:nth-child(1)>p{
  text-align: center;
  padding: 1px;
  font-size: 20px;
}
.horizontal-scroll-container img{
  width: 180px;
  border-radius: 120px;
  margin-left: 110px;
}
.h-21{
  animation: animateBg 5s linear infinite;
    background-image: linear-gradient(90deg,#b399a3,#b9bba9,#bfaea8,#b5d2cb,#aba1b4);
    background-size: 400% 100%;
    border-radius: 10px;
}
.h-21 div:nth-child(2)>p{
  text-align:center;
 
  margin-right: 14px;
}

.div32 button:hover{
  cursor: pointer;
  background-color: rgba(66, 59, 59, 0.306);
}
.div32 button{
  margin-block-start: auto;
  margin-left: auto ;
  padding: 7px 20px;
}
.f-animated-gradient {
    animation: animateBg 5s linear infinite;
    background-image: linear-gradient(90deg,#418171,#5d2c8c,#8d452a,#418171,#5d2c8c);
    background-size: 400% 100%;
  }
  @keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }

  .t-1 {
    display: flex;
    margin: 60px;
    justify-content: center;
}

.t-2 {
    text-align: center;
    font-size: 20px;
    margin-top: 40px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #2980b9;
}

.t-3,
.t-6 {
    font-size: 30px;
    border-radius: 40px;
    padding: 0px 30px;
    margin: 0px 10px;
    padding-top: 32px;
}

.t-3 {
    border-top-right-radius: 240px;
    border-bottom-right-radius: 240px;
    margin-right: -20px;
}

.t-6 {
    border-top-left-radius: 240px;
    border-bottom-left-radius: 240px;
    margin-left: -20px;
}

.t-4,
.t-5 {
    padding: 19px 10px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.t-5 {
    text-align: right;
    padding-left: 15px;
    padding-right: 20px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.t-6 {
    font-size: 40px;
    border-radius: 45px;
    padding: 0px 20px;
    padding-top: 25px;
}
.F-01{
     animation: animateBg 15s linear infinite;
    background-image: linear-gradient(to right,#b8c8cc,#9299bf,#c9cdb6,#b9ab9c,#afb3c7);
    background-size: 400% 100%;
  padding:40px 0px;
  border-radius: 30px;
}
.F-1{
 align-items: center;
 display: flex;
 justify-content: center;
}
.F-1>button{
  margin-left: 27px;
  background-color: #5d2c8c;
  border-radius: 10px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}
.F-0{
  text-align: center;
  font-size: 47px;
}
.F-2{
  display: flex;
  margin: 20px;
  margin-left: 160px;
  margin-right: 160px;
}
.F-2>div{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.F-2>div:nth-child(1)>p{
  font-size: 29px;
  color: black;
}
.F-2>div:nth-child(2){
  margin-left: 10px;
  margin-top: 3px;
  font-size: 16px;
}
.vertical-line {
  margin-left: 50%;
  width: 5px;
  min-height: 1030px;
  background-color: #2980b9;
  
}
#w-0{
  text-align: center;
  font-size: 50px;
}
.w-2{
  font-size: 80px;
  color: rgb(184, 31, 31);
  padding: 20px;
  border-radius: 120px;
  background-color: #2980b9;
  margin: 0px 80px;
}
.w-1{
  display:flex;
 margin: 30px 5%;
 font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif ;
}
.w-1>div:nth-child(1)>div{
  text-align: right;
  margin: 0px;
  margin-right: 60px;
  margin-top: 90px;
  margin-bottom: 90px;
}
.w-1>div:nth-child(3)>div{
  margin: 0px;
  margin-left: 60px;
  margin-top: 90px;
  margin-bottom: 90px;
}
.w-1>div:nth-child(1)>div>h3{
  position: relative;
  right: -35.5%;
}

.w-1>div:nth-child(3)>div>h3{
  position: relative;
  left: -28.25%;
}

.w-1>div>div>h5{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #5d2c8c;
}
.student-dashboard .track-path {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

body {
  font-family: Roboto, sans-serif;
  line-height: 1.15;
  font-size: 14px;
}

@media (min-width: 1200px) {
  body {
    font-size: 15px;
  }
 
}

@media (min-width: 1300px) {
  body {
    font-size: 16px;
  }
  
}

@media (min-width: 1400px) {
  body {
    font-size: 18px;
  }
  
}

*,
div {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
  overflow: scroll;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
  box-sizing: border-box;
  font-size: 14px;
}

@media (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1300px) {
  html {
    font-size: 16px;
  }
  
}

@media (min-width: 1400px) {
  html {
    font-size: 18px;
  }
}

html {
  height: 100%;
}

.card-8el {
  background: #fff;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 15px;
  width: 100%;
}

.track-a2t {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

.student-dashboard .track-a2t::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #8c4848;
  border-radius: 15px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.student-dashboard .track-a2t::-webkit-scrollbar-thumb {
  background: rgba(140, 72, 72, 0.35);
}

::-webkit-scrollbar-track {
  background: hsla(68, 62%, 91%, 0.302);
}

p {
  box-sizing: border-box;
  margin: 0;
}

.kmrkj {
  margin-bottom: 0.5rem;
}

.tteph {
  margin-top: 0;
}

.header-omc {
  color: #b00;
  font-size: 85%;
  font-style: italic;
  font-weight: 700;
}

.header-g6v {
  color: #625b71;
  display: flex;
  font-size: 20px;
  font-style: normal;
  justify-content: space-between;
}

.rel-iat {
  position: relative;
}

.student-dashboard .course-gba::-webkit-scrollbar {
  width: 2px;
}

.student-dashboard .course-gba::-webkit-scrollbar-thumb {
  background: hsla(0, 66%, 92%, 0.65);
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

#course {
  margin-left: 170px;
}

.course-gba .header-omc {
  margin-left: 6.5%;
  width: 95%;
}

.course-gba .header-old {
  color: #805008;
}

.course-gba .button-do7 {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  min-height: 30px;
  padding: 5px 0;
}

.course-gba .button-do7 p {
  font-size: 120%;
  margin-left: 2%;
  text-align: left;
}

.course-gba .jou-eyx .ass-wwv {
  margin-left: 10%;
  width: -webkit-max-content;
  width: max-content;
}

.fle-kgb {
  display: flex;
}

.item-e6i {
  align-items: center;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.course-gba .jou-eyx .ass-wwv .ass-phf {
  align-items: center;
  border-left: 5px solid #b7c9bc;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  text-decoration: none;
}

.student-dashboard .course-gba .jou-eyx .ass-wwv .ass-phf:hover {
  background-color: hsla(0, 66%, 92%, 0.65);
}

img {
  border-style: none;
  max-width: 100%;
}

.course-gba .jou-eyx .ass-wwv .icon-vro {
  height: 20px;
  margin-left: -25px;
  width: 25px;
}

.gre-913 {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.course-gba .jou-eyx .ass-wwv .ass-phf .logo-x1g {
  margin-right: 10px;
  padding: 0;
}

.course-gba .jou-eyx .ass-wwv .ass-phf .logo-x1g {
  align-items: center;
  display: flex;
  height: 30px;
  margin-left: 10px;
  width: 30px;
}

.course-gba .jou-eyx .ass-wwv .ass-phf .logo-x1g img {
  width: 25px;
}

.course-gba .jou-eyx .ass-wwv .ass-phf p {
  color: #474745;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis!important;
  white-space: nowrap;
  width: 20vw;
}

.course-gba .jou-eyx .ass-wwv .ass-phf .assignment-ahw {
  margin-top: 0;
}

.nwhyx {
  margin-bottom: 0;
}

.fejpk {
  margin-top: 0.25rem;
}

.cnooh {
  font-size: 0.875rem;
}

.course-gba .jou-eyx .ass-wwv .ass-phf .poi-pws {
  font-size: 13px;
}

.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.course-gba .jou-eyx .ass-wwv .ass-phf svg {
  fill: #e0a503;
  height: 20px;
  width: 20px;
}

.course-gba .jou-eyx .ass-wwv .ass-phf .logo-x1g svg {
  fill: #b00;
  height: 100%;
  margin-right: 10px;
  padding: 0;
  width: 100%;
}

.pbeeg {
  font-weight: 600;
}


#style-fpc9M.style-fpc9M,
#style-zNUDs.style-zNUDs,
#style-En7e4.style-En7e4,
#style-IRivJ.style-IRivJ,
#style-mGSI4.style-mGSI4,
#style-Z1M7l.style-Z1M7l,
#style-woRYL.style-woRYL,
#style-CCmsq.style-CCmsq,
#style-HWXDs.style-HWXDs,
#style-fz7wc.style-fz7wc,
#style-sh1n1.style-sh1n1,
#style-3DkP1.style-3DkP1,
#style-noTo6.style-noTo6,
#style-GdnmJ.style-GdnmJ,
#style-Lafsi.style-Lafsi,
#style-eEchO.style-eEchO,
#style-CCVv7.style-CCVv7,
#style-9Wht6.style-9Wht6,
#style-Zg5n5.style-Zg5n5,
#style-oSfMh.style-oSfMh,
#style-Dp8dd.style-Dp8dd,
#style-el6jh.style-el6jh,
#style-qKVUY.style-qKVUY,
#style-oVaa5.style-oVaa5,
#style-fraFM.style-fraFM,
#style-eEc3e.style-eEc3e,
#style-dXLaK.style-dXLaK,
#style-n2gMm.style-n2gMm,
#style-T1ozJ.style-T1ozJ,
#style-TJQ2i.style-TJQ2i,
#style-wiaQ4.style-wiaQ4,
#style-wp2Ha.style-wp2Ha,
#style-5M8rs.style-5M8rs,
#style-N7JQD.style-N7JQD,
#style-qc4yf.style-qc4yf,
#style-rlE3T.style-rlE3T,
#style-Er8HP.style-Er8HP {
  border-color: rgba(130, 227, 128, 0.5);
}

#style-txZeE.style-txZeE,
#style-PSq5C.style-PSq5C,
#style-mqQDf.style-mqQDf,
#style-EaWpa.style-EaWpa,
#style-q22nn.style-q22nn,
#style-f3ARO.style-f3ARO,
#style-BEyYl.style-BEyYl,
#style-F3lfw.style-F3lfw,
#style-o4sIY.style-o4sIY,
#style-E7yrH.style-E7yrH,
#style-FaB92.style-FaB92,
#style-vHxJL.style-vHxJL,
#style-pv9fj.style-pv9fj {
  border-color: rgb(237, 221, 221);
}


*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}
.Dare0{
  display: flex;
  margin: 20px;
  margin-top: -2%;
}
.Dare0 img{
   width: 29px;
   margin-left: 20px;
   margin-top:20px ;
   
}

.sh{
margin-top: -70px;
margin-bottom: 20px;
}
.Dare0 h3,.Dare01 h3{
  padding: 10px;
  padding-top: 33px;
  font-size: 38px;
  font-style: italic;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-image: linear-gradient(to left, indigo, green, blue, rgb(255, 0, 68), orange, red);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.div1{
  font-size: 17px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-image: linear-gradient(to left, indigo, green, blue, rgb(255, 0, 68), orange, red);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-left: 10px;
}
.Dare1{
  display: flex;
  align-items: center;
  margin-left: 200px;
  margin-right: 200px;
  padding-bottom: 6%;
}
.Dare1 span{
  padding: 4px 30px;
  padding-left: 60px;
  border-radius: 10px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: rgb(235, 230, 224);
}
.Dare1 span:nth-child(1),#Dare11 span:nth-child(2),#Dare11 span:nth-child(3){
  background-image: linear-gradient(to top, rgb(94, 81, 81), red);
}
#Dare22 span:nth-child(2),#Dare22 span:nth-child(3),#Dare22 span:nth-child(4),#Dare22 span:nth-child(5)
{
  background-image: linear-gradient(to top, rgb(94, 81, 81), red);
}
.Dare1 img{
  width: 30px;
}
.divider {
  flex-grow: 1;
  margin: 5%;
}
.Dare2{
  display: flex;
 
  padding: 0px 12%;
  margin-left: 50px;
}
.Dare2 iframe{
  border-radius: 10px;
  margin-right: 40px;
}
.Dare2 p{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 500;
 line-height: 20px;
 font-size: 16px;
}
.btnn{
  margin-top: 18%;
  margin-left: 18%;
  font-size: 16px;
  padding: 10px;
  padding-left: 17px;
  padding-right: 17px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border-radius: 17px;
  color: white;
  background-image: linear-gradient(to top, rgb(94, 81, 81), red);
  border: none;
}
.btnn:hover{
  cursor: pointer;
}
.world{
  margin-left: 6%;
}
a:hover{
  cursor: pointer;
}
.div3{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 2%;
 
}
#dare15 p{
  
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-right: 60px;
  font-size: 20px;
}

.anotherfor{
  margin-top: 8%;
}

.imgspecial{
  border-radius: 20px;
}
.interv{
  margin-top: 7%;
}

.home11{
  margin-top: 3%;
}

.custom-width{
  margin-left: -10%;
  margin-top: 27%;
  margin: 0 auto; 
  margin-top: 27%;
}

.player{
  margin-left: 5%;
}
.addyoga{
  display: flex;
 
}
.homewor{
  margin-top: 3%;
  max-width: fit-content;
}

.hel{
  align-items: center;

  background-color:white;
  width: 100%;
}
.mto{
  margin-top: -5%;

  width: 100%;
}
.imagedata{
  margin-left: 15%;
}
.aboutcolor{
  color: #0B60B0;
}
.bgdev{
  background-color:black;
  padding-bottom: 30px;
}


/* Add this to your existing CSS file or create a new one */

/* Default styling */
.text-md-start {
  margin: 0; /* Reset margin for default size */
}

/* Styling for iPad and below */
@media (max-width: 768px) {
  .sign{
    margin-top: 20%;
  }
  .my-md-5 {
    margin-top: 3rem; /* Adjust top margin for smaller devices */
    margin-bottom: 2rem; /* Adjust bottom margin for smaller devices */
  }

  .my-md-8 {
    margin-top: 3rem; /* Adjust top margin for smaller devices */
    margin-bottom: 2rem; /* Adjust bottom margin for smaller devices */
  }
}
.carousel{
  
  color: black;
}
.road{
  margin-top:10% ;
  margin-bottom: 3%;
}
.road1{
  margin-bottom: 2%;
}

@media (max-width: 768px) {
  .sign{
    margin-top: 20%;
  }
  .my-md-5 {
    margin-top: 3rem; /* Adjust top margin for smaller devices */
    margin-bottom: 2rem; /* Adjust bottom margin for smaller devices */
  }

  .my-md-8 {
    margin-top: 3rem; /* Adjust top margin for smaller devices */
    margin-bottom: 2rem; /* Adjust bottom margin for smaller devices */
  }
  #class-1{
    margin-top: -5vh;
  }
}

@media screen and (min-width: 769px) and (max-device-width: 828px){
  #class-1{
    margin-top: -2.65vh;
  }
  .imageTack{
    margin-bottom: -3.8%;
  }
  
}
@media screen and (min-width:768px) and (max-width: 1380px) {
  .img-colleges{
    margin-top: 70px;
    width: 100%;
    height: 140%;
  }
}
@media screen and (min-width: 768px) {
  .hire{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}
@media screen and (min-width: 1200px) {
  #class-1{
    margin-top: -5.6vh;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1499px){
  #class-1{
    margin-top: -6.9vh;
  }
}

@media screen and (min-width: 1500px) {
  #class-1{
    margin-top: -5.9vh;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 
  #class-1{
    margin-top: -4.1vh;
  }
  .sign{
    margin-top: 10%;
  }
  .c-0{
    display: block;
  }
  .c-1{
    margin:auto;
    padding-left: 2%;
  }
  .c-4{
    width: 70%;
  }
  .c-6{
    display: block;
    width: 100%;
  }
 
  #c-9,#c-10,#c-14,#c-15,#c-17{
    width: 90%;
  }
  #linee{
    height: 90%;
  }
  .w-2{
    /* margin-left: -3%;
    margin-right: -3%; */
    
    margin: 0px;
    margin-left: -4%;
    margin-right: 0;
  }
  .w-1>h3{
    border: 2px solid black;
  }
  #logoimage{
    margin-top: -5%;
    border: 2px solid black;
  }
}

@media only screen and (max-device-width: 480px) {
  
  .sign{
    margin-top: 30%;
  }
  .c-0{
    display: block;
  }
  .c-1{
    margin:auto;
    padding-left: 2%;
  }
  .c-4{
    width: 100%;
  }
  .c-6{
    display: block;
    width: 100%;
  }
  .c-18{
    display: block;
    width: 90%;
  }
  #c-9,#c-10,#c-14,#c-15,#c-17{
    width: 43%;

  }
  #c-15{
    margin-left: -2%;
  }
  .div32{
    display: block;
  }
  .home11{
    margin-top: 9%;
  }
  .t-5{
    display:block;
 
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    height: 270px;
   width: 600px;
  }
  .t-3,.t-6{
   
    padding-top: 40%;
  }
  .t-6{
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
  }
  .t-1{
    display:none;
  }
  #linee{
    display: none;
  }
  .w-1{
    display: block;
    width: 100%;
  }
  .w-1>div{
    display: flexbox;
    width: 75%;
  }
  .F-1{
   display:inline-block;

    }
    #faq{
      width: 19vb;
    }
    .F-2{
      width: 39vb;
    
      margin-left: 2%;
    }
  
    #class-1{
      margin-top: -4.99vh;
    }
}


@media screen and (max-width: 369px) {
  #class-1{
    margin-top: -5.7vh;
  }
}
@media screen and (max-width: 389px) {
  #class-1{
    margin-top: -6.3vh;
  }
}
@media screen and (min-width:411px) and (max-width: 450px) {
  #class-1{
    margin-top: -4.6vh;
  }
}
@media screen and (min-width:913px) and (max-width: 1024px) and (min-height : 1300px){
  #class-1{
    margin-top: -3.1vh;
  }
}
@media screen and (min-width:913px) and (max-width: 1024px) and (max-height : 700px){
  #class-1{
    margin-top: -7vh;
  }
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.large-icon {
  font-size: 48px;
}
.sign{
  padding: 0;
  padding-top: 10%;
}

.class-1{

  margin: 5%;
  margin-top: -3.6%;
  padding: 3% 0;
  border-radius: 40px;
}
.class-2{
  background-image:url(../images/image21.jpg);
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image within the container */
 padding-bottom: 2%;
margin-top: 10%;
}
.counse{
  background-image:url(../images/image21.jpg);
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image within the container */
}

.class-3{
  background-image: url('https://img.freepik.com/free-vector/line-background-wave-gradient-template-design_483537-5081.jpg?w=1380&t=st=1711528134~exp=1711528734~hmac=384c7845905474110f424cd34c7d33151de26162d452b1cbaca6ee98490bea37');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image within the container */
}
.text-green-500 {
  color: green;
  margin-right: 2%;
}
h1{
  font-size: 35px;
}
h2{
  font-size: 28px;
}
h3,h4,h5{
  font-size: 23px;
}
#chooseC{
  border-radius: 20px;
}

.seminar-image {
  height: 400px; /* Initial height */
  max-width: 100%; /* Set maximum width */
  object-fit: cover; /* Maintain aspect ratio */
  display: block; /* Make the image a block-level element */
  margin: 0 auto; /* Center the image horizontally */
  transition: height 0.5s ease; /* Smooth transition for height change */
  border-radius: 20px;
}

.seminar-image:hover {
  height: 80vh; /* Height on hover */
}

