@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.15;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  margin: 0;
  overflow: scroll;
  height: 100%;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
  font-size: 14px;
  height: 100%;
  box-sizing: border-box;
}

@media (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1300px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 18px;
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

div {
  box-sizing: border-box;
}



.content-wxz {
  width: 140%;
  height: 10%;
  overflow: auto;
  padding: 10px;
}

.switch-lj6 {
  align-items: center;
  background-color: #fff!important;
  border-top: 1px solid #dbdbdb;
  display: flex;
  height: 8%;
  justify-content: space-between;
  padding: 10px;
}

.section-vzo .tabs-oan {
  grid-column-gap: 1rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.switch-lj6 .all-hhz {
  align-items: center;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  color: #555;
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  padding: 3px 5px;
}

.switch-lj6 .switch-rzi {
  grid-column-gap: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.section-vzo .tabs-oan .citvr {
  border-radius: .5rem;
  cursor: pointer;
  padding: .5rem 1rem;
  transition: all .3s;
}

.section-vzo .tabs-oan .select-yol,
.new-task-section .body .left-div .section-ogl .section-vzo .tabs-oan .citvr:hover,
.new-task-section .body .left-div .section-ogl .section-vzo .tabs-oan .select-yol,
.new-task-section .body .left-div .section-ogl .section-vzo .tabs-oan .citvr:hover {
  background-color: #e9e9e9;
}

p {
  box-sizing: border-box;
  margin: 0;
}

.content-wxz .task-gcw .task-mvp {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0 10px;
  
}

.content-wxz .task-gcw .header-y3j {
  grid-gap: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  padding: 10px;
}
.content-wxz .task-gcw .des-8v9 {
  margin-top: 1%;
  padding-bottom: 5%;
}

.MuiSvgIcon-root,
.css-vubbuv {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.switch-lj6 .all-hhz svg,
.switch-lj6 .switch-rzi .row-ovd svg {
  margin-right: 5px;
  width: 20px;
}

.switch-lj6 .switch-rzi .tasks-3g6 {
  color: #7c7c7c;
  font-weight: 500;
}

.switch-lj6 .switch-rzi .row-ovd {
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 25%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.fle-ajg {
  display: flex;
}

.item-971 {
  align-items: center;
}

.content-wxz .task-gcw .des-8v9 .title-chv {
  font-size: 16px;
  font-weight: 500;
  margin-top: 2%;
}

.ql-6os {
  word-wrap: break-word;
  box-sizing: border-box;
  height: 100%;
  line-height: 1.42;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}

.ql-5xn {
  box-sizing: border-box;
}

.gkajs {
  text-align: left;
}

.content-wxz .task-gcw .des-8v9 .ql-6os {
  margin-top: 2%;
  padding: 0!important;
}

.switch-lj6 .switch-rzi .row-ovd svg {
  fill: #353535;
  height: 20px;
  width: 20px;
}

.header-y3j{
  width: 100%;
  background-color:white;
}
.nrbjj {
  font-weight: 500;
}

.kwgbx {
  margin-left: .5rem;
}

.gre-4sh {
  color: #19a974;
}

.ql-6os > * {
  cursor: text;
}

.ql-5xn * {
  box-sizing: border-box;
}

.ql-6os p {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

strong {
  font-weight: bolder;
}

.content-wxz strong {
  font-weight: 500!important;
  
}

a {
  background-color: initial;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
}

.ql-5xn a {
  color: #06c;
}

.img11{
  width:10px
}
.page1{
    display: flex;
    justify-content: space-between;
   background-color: #7c7c7c;
  }

.page1>div:nth-child(2){
  margin-right: 20%;
   margin-left: 5%;
 
}
.page1{
  height: 100vh;
  width: 80%;
  margin-top: 5%;
  padding: 5% 0px;
  background-color:white;

}

.plac1{
width: 185vb;
padding:0px 7%;
top: 0;
position: fixed;
background-color: rgb(105, 197, 223);
overflow:visible;
}

.task-mvp{
  color: #309fa5;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  text-decoration: underline;
}
.switch-lj6{
  position: fixed;
  bottom: 0;
  overflow: hidden;
  width: 85%;
}