.dashboard-sidebar ul a li{
 padding: 0.8rem 0.5rem;
 background-color: aqua;
 border-radius: 1rem;
 font-weight: bold;
}
.dashboard-sidebar{
    width: fit-content;
    margin-top: 80px;
}
.placement{
    background-color: white;
}

.dashboard-sidebar:hover .toggle{
  width: 100%;
  transition: all 500ms;
}
.dashboard-sidebar .toggle{
    width: 0;
    overflow: hidden;
    transition: all 500ms;
}
.placement-dashboard .dashboard-right{
    width: 100%;
}
.topic-roadmap,.upcoming{
    max-height: 90vh; 
    overflow-y: scroll;
}
.topic-roadmap::-webkit-scrollbar,.upcoming::-webkit-scrollbar{
    width: 5px;
}
.placement-dashboard{
    overflow-x: hidden;
 
}
.profile-hov:hover{
    background: wheat;
    cursor: pointer;
}