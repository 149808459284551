/* Placement.css */
.placement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
  }
  .placement-container {
    background: radial-gradient(circle farthest-side at left, #a89581, #b992a0, #a77f93, #7797a2, #9fb7c9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
 
  .placement-card {
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
  
  .placement-card img {
    width: 40vb; /* Adjust the image width as needed */
    height: 200px; /* Adjust the image height as needed */
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .placement-details {
    text-align: center;
  }
  
  .placement-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .placement-company {
    font-size: 16px;
  }
  
  .placement-buttons {
    margin-top: 10px;
    margin-left: 44%;
  }
  
  .placement-buttons button:disabled {
    color: #666;
    cursor: not-allowed;
  }
  .placement-buttons button:hover {
    color: #1c9cbc;
  }
  