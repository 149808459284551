/* Service.css */


  
.container-1 {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  margin-top: 30px;
  padding: 20px;
  text-align: center;
  background-color: #b7d6e4;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #e44d26;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.section {
  margin-top: 40px;
}

ol {
  list-style: none;
  padding: 0;
}

.section li {
  margin: 20px;
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 15px;
  background-color: #b7d6e4;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.section li:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  background: linear-gradient(45deg, #e74c3c, #f39c12);
  color: #fff;
}

.btn1 {

  color: #fff;
  border: none;
  padding: 18px 36px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.foooter {
  margin-top: 40px;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 30px;
  border-radius: 15px;
}

.foooter p {
  margin: 0;
}

/* Additional Styles for Different Elements */

h3 {
  color: #3498db;
}

section:nth-child(odd) {
  background-color: #ecf0f1;
}

section:nth-child(even) {
  background-color: #f8f9fa;
}

/* Add more styles as needed */
