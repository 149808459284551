@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');


ul{
    list-style: none;
    margin: 0;
}
main{
    overflow: hidden;
}


.login .form-control:focus,.register .form-control:focus{
    outline: none;
    box-shadow: none;
  }
  .Index{
    font-family: 'Roboto', sans-serif;
  }

  .right-to-left{
  	animation: rtl 15s 2s linear infinite alternate;
  }
  @keyframes rtl {
    from {
      transform: translateX(20%);
    }
    to {
      transform: translateX(-20%);
    }
  }

  .left-to-right{
    animation: ltr 15s 2s linear infinite alternate-reverse;
  }
  @keyframes ltr {
    from {
      transform: translateX(-40%);
    }
    to {
      transform: translateX(40%);
    }
  }

  
circle {
  transition: all 0.7s ease-in-out;
}
