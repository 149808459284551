@media (max-width:600px) {
    
.dashboard-sidebar .response{
    background: white;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
}
}
