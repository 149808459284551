/* MeetOurTeam.css */

.meet-our-team {
    background-color: #f8f9fa; /* Light background color */
  }
  
  .team-member {
    padding: 20px;
    background-color: #b1c1cbb5; /* White background */
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .team-member img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .team-member img:hover {
    transform: scale(1.1);
  }
  
  .member-info {
    margin-top: 15px;
    padding-bottom: 2%;
  
  }
  
  .member-info h4 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .member-info p {
    font-size: 18px;
    color: #6c757d; /* Text color */
    
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: transparent;
  }
  
  .carousel-indicators {
    bottom: -30px; /* Adjust position of indicators */
  }
  
  .carousel-indicators li {
    background-color: rgba(0, 0, 0, 0.5); /* Indicator color */
  }
  
  .carousel-indicators .active {
    background-color: #343a40; /* Active indicator color */
  }
  