@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');


@media (min-width: 1200px) {
  body, html {
    font-size: 15px;
  }
}

@media (min-width: 1300px) {
  body, html {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  body, html {
    font-size: 18px;
  }
}

.assignment-foj {
  grid-column-gap: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;

  max-height: 70vh;
  min-height: 70vh;
  overflow: auto;

}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8c4848;
  border-radius: 15px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-track {
  background: hsla(68, 62%, 91%, .302);
}

.assignment-foj .rig-phw {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 60vh;
  min-height: 60vh;
  overflow: auto;
  padding: 0;
  width: 100%;
}

.assignment-foj .rig-phw::-webkit-scrollbar {
  width: 5px;
}

.assignment-foj .rig-phw::-webkit-scrollbar-thumb {
  background: rgba(140, 72, 72, .35);
}

.assignment-foj .lef-cv9 .box-vth {
  grid-column-gap: 10px;
  background-color: #ba0001;
  justify-content: center;
  min-height: 20%;
  padding: 1rem;
  width: 80%;
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.assignment-foj .lef-cv9 .middle-ite::-webkit-scrollbar {
  width: 5px;
}

.assignment-foj .lef-cv9 .middle-ite::-webkit-scrollbar-thumb {
  background: rgba(140, 72, 72, .35);
}

.fle-fok {
  display: flex;
}

.item-ycb {
  align-items: center;
}

.justify-6c5 {
  justify-content: flex-end;
}

.orqly {
  padding-bottom: .25rem;
}

.right-c1e {
  border: 1.5px solid #e4e4e4;
  border-radius: 10px;
  width: 120vb;
  max-height: 60vh;
  min-height: 40vh;
  overflow: auto;
  color: blueviolet;
}

p {
  margin: 0;
}

.assignment-foj {
  max-height: 30%;
  padding: 10px 0;
  width: 100%;
  margin-left: 15%;
}

.assignment-foj .lef-cv9 .middle-ite .des-xhb {
  padding: 1rem 0;
  width: 100%;
}

.bvadh {
  padding: .25rem;
}

.btn-zle {
  text-align: center;
  text-decoration: none;
  border: 1px solid #b00;
  border-radius: 5px;
  color: #b00;
  font-weight: 500;
}

.assignment-foj .rig-phw table {
  border: none;
  padding: 0;
  width: 100%;
}

.assignment-foj .rig-phw table th {
  background-color: #94baca;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px 5px;
  text-align: left;
  font-weight: 500;
}

.assignment-foj .rig-phw table td {
  padding: 10px 5px;
  text-align: left;
  font-weight: 500;
}

.CircularProgress-small {
  height: 6vw !important;
}

.jmtot {
  margin-top: .5rem;
}

.assignment-foj .lef-cv9 .middle-ite .circles-9cm .svgs-b2q .progress-enn .tasks-rmn {
  margin-top: 5%;
}

.assignment-foj .rig-phw table .completed-whm {
  background-color: #e9ffe0;
  border-radius: 5px;
  color: #61b958;
  font-size: 85%;
  padding: 5px 10px;
}

.style-ckn{
  text-decoration: none;
  color:black;
}
.style-ckn:hover{
  color: #61b958;
}
