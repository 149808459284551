body {
  font-family: 'Arial', sans-serif;
  background: #f5f5f5;
  margin: 0;
  padding: 0;

}

.about-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
 
}

header {
  text-align: center;
}

header h1 {
  color: #3498db; /* Dodger Blue */
}

header p {
  color: #555;
  font-style: italic;
}

section {
  margin-top: 20px;
  cursor: pointer;
  background: #ecf0f1; /* Clouds */
  padding: 15px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

section h2 {
  color: #2c3e50; /* Midnight Blue */
  position: relative;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.accordion-content {
  display: none;
  padding: 10px;
  border: 1px solid #bdc3c7; /* Silver */
  border-radius: 5px;
  margin-top: 10px;
  background: #fff;
  color: #555;
}

.accordion-content.active {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  color: #555;
}

footer {
  margin-top: 20px;
  text-align: center;
  color: #7f8c8d; /* Silver Text */
}