/* Gallery.css */
.gallery-container {
  overflow: hidden;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto;
}

.image-scroll {
  display: flex;
  animation: scrollImages linear infinite 20s; /* Adjust the duration as needed */
}

@keyframes scrollImages {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

#checkimg {
  width: 100%;
  height: auto;
  object-fit: cover; /* Adjust the object-fit as needed */
}


/* Gallery.css */
.gallery-container {
    position: relative;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.5s ease;
  }
  
  .gallery-container.zoomed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
  }
  
  .gallery-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    transition: transform 0.5s ease;
  }
  
  .gallery-image.zoomed {
    transform: scale(1.5); /* You can adjust the scale factor as needed */
  }
  .navigation-buttons{
    margin-left: 44%;
  }
  .navigation-buttons button:hover {
   color: #45a049;
  }
  